

export const COVERS = [
    {
        id: 0,
        type: 's',
        desc: "School IT",
        src: "http://charlessuits.org/sites/MrSuits.net/charlessuits/img/schoolITDirector.docx"
    },
    {
        id: 1,
        type: 's',
        desc: "Base Letter",
        src: "http://charlessuits.org/sites/MrSuits.net/charlessuits/img/basicCover.docx"
    }    
    
]
    
    
    