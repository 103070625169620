import React from 'react';
import { Card, CardImg, CardText, CardBody, CardTitle } from 'reactstrap';
import Iframe from './Iframe';


function Home(props) {
    return (
        <div className="container" >
            
        
         
           
                <Iframe className="full-height full-width" source="https://www.charlessuits.org/sites/MrSuits.net/cvResume" />
           
            </div>
              
    );
}

export default Home;  